/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.qrCode-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrCode {
  margin: 0 20px;
}

.blur {
  opacity: 0.25;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-btn {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.action-buttons button:last-child {
  margin-bottom: 0;
}

.btn-disabled {
  cursor: not-allowed !important;
}

/* .editor-styling{
    border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
} */
.text-editor p {
  margin-top: 0;
  margin-bottom: 0;
}

.text-editor p:first-child {
  margin-top: 15px;
}
.text-editor p span span span {
 opacity: 1 !important;
 margin-top: 16px;

}
.text-editor p:last-child {
  margin-bottom: 15px;
}

.hidden {
  display: none;
}

.video-input {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  width: calc(100% - 22px);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
}

.choose-file-btn {
  font-size: 16px;
  margin-right: 5px;
}

.uploading-text {
  font-size: 12px;
  margin-top: -7px;
}

.rating-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.rating-content {
  width: 350px;
  padding: 10px 20px;
}

.rating-content h2 {
  margin-bottom: 15px;
}

.rating-stars {
  display: flex;
  align-items: center;
  gap: 10px;
}

.star {
  cursor: pointer;
  transition: fill 200ms;
}

.rating-comment {
  width: 100%;
  padding: 5px 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-top: 10px;
  margin-bottom: 15px;
}

.submit-rating-btn {
  background-color: green;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 15px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.rating-success {
  width: 350px;
  padding: 20px;
  margin: 30px 0 40px 0;
  border: 1px solid black;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 425px) {
  .qrCode-container {
    flex-direction: column;
  }

  .action-buttons {
    flex-direction: row;
    margin: 10px 0;
  }

  .action-btn {
    margin-right: 1rem;
  }

  .action-buttons button:last-child {
    height: fit-content;
    margin-right: 0;
  }
}

.datepicker-container {
  width: 100%;
  max-width: 200px;
  font-size: 14px;
}

.datepicker-input {
  background-color: white !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-size: 14px !important;
  width: 100% !important;
  height: 32px !important;
  cursor: pointer !important;
}

.datepicker-input:focus {
  outline: none !important;
  border-color: #f1356d !important;
}

.datepicker-toggle {
  padding: 2px !important;
}

/* Make datepicker more compact on mobile */
@media (max-width: 600px) {
  .datepicker-container {
    max-width: 150px;
  }
  
  .datepicker-input {
    font-size: 12px !important;
    padding: 2px 6px !important;
    height: 28px !important;
  }
}